import * as React from "react"
import Page from '../../components/Page/page';

const NotFoundPage = ({ location }) => (
    <Page location={location} title="Page not found!" seoTitle="404 - Page not Found" subtitle="Oops! We couldn't find the page you were looking for.">
        <p>You just hit a page that doesn&#39;t exist... the sadness 😿.</p>
    </Page>
)

export default NotFoundPage
